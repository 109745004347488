import { useEffect, useRef, useState } from 'react';
import './formulaire2.scss'
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import userImg from './../../../assets/user.png'
import Webcam from 'react-webcam';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import Swal from 'sweetalert2';
import Select from 'react-select';
import config from '../../../config'
import Cropper from 'react-easy-crop';
import { CloudUploadOutlined } from '@mui/icons-material';
moment.locale('fr');


const Formulaire2 = ({handleModalClose}) => {

    const DOMAIN = config.REACT_APP_SERVER_DOMAIN
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [source, setSource] = useState('import');
    const [photo, setPhoto] = useState(null);
    const [competenceOption, setCompetenceOption] = useState([]);
    const [type, setType] = useState([]);
    const [niveau, setNiveau] = useState([]);
    const [statusE, setStatusE] = useState([]);
    const webcamRef = useRef(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [departement, setDepartement] = useState([]);
  
    const handleFileChange = (event) => {
      setPhoto(event.target.files[0]);
    };
    const handleSourceChange = (event) => {
      setSource(event.target.value);
    };
    const handleSelectChange = (selectedOption, fieldName) => {
      setData((prev) => ({ ...prev, [fieldName]: selectedOption.value }));
    };
      
    const handleChange = (e) => {
      const fieldName = e.target.name;
      const fieldValue = e.target.value;
      
      if (fieldName === "email") {
        const lowercaseValue = fieldValue.charAt(0).toLowerCase() + fieldValue.slice(1);
        setData((prev) => ({ ...prev, [fieldName]: lowercaseValue }));
      } else if (Number.isNaN(Number(fieldValue))) {
        const capitalizedValue = fieldValue.charAt(0).toUpperCase() + fieldValue.slice(1);
        setData((prev) => ({ ...prev, [fieldName]: capitalizedValue }));
      } else {
          setData((prev) => ({ ...prev, [fieldName]: fieldValue }));
      }
    }

      const handlePhotoSubmit = async () => {
         if (!data.first_name || !data.last_name || !data.date_of_birth || !data.gender || !data.address || !data.etat_civil || !data.identification_type || !data.skills || !data.certifications || !data.employment_status) {
          Swal.fire({
            title: 'Error',
            text: 'Veuillez remplir tous les champs requis',
            icon: 'error',
            confirmButtonText: 'OK',
          });
          return;
        }
        navigate('/personnel')

        try {
         let photoSrc;
          if (source === 'import') {
            photoSrc = photo
          } else if (source === 'webcam') {
            photoSrc = webcamRef.current.getScreenshot();
          }
          
          await axios.post(`${DOMAIN}/api/admin/employe`, { ...data, source: photoSrc});
          await Swal.fire({
            title: 'Success',
            text: 'Employé créé avec succès!',
            icon: 'success',
            confirmButtonText: 'OK'
          });
          window.location.reload();
         
        } catch (error) {
          await Swal.fire({
            title: 'Error',
            text: error.message,
            icon: 'error',
            confirmButtonText: 'OK'
          });
        
          console.log(error);
        }
      };

      useEffect(() => {
        const upload = async () => {
          try {
            if (source === 'webcam') {
              const screenshot = webcamRef.current.getScreenshot();
              console.log(screenshot)
            } else {
              const formData = new FormData();
              formData.append('file', photo);
              const base64File = await convertToBase64(photo);
              setPhoto(base64File); // Convertir le fichier en base64
            }
          } catch (error) {
            console.log(error);
          }
        };
      
        upload();
      }, [source, photo]);
    

      function convertToBase64(file){
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result)
          };
          fileReader.onerror = (error) => {
            reject(error)
          }
        })
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get(`${DOMAIN}/api/admin/competence`);
            setCompetenceOption(res.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get(`${DOMAIN}/api/admin/niveau`);
            setNiveau(res.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => {
        const fetchData = async () => {
          try {
            const res = await axios.get(`${DOMAIN}/api/admin/typepiece`);
            setType(res.data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => {
        const fetchData = async () => {
          try {
            const {data} = await axios.get(`${DOMAIN}/api/admin/status`);
            setStatusE(data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const {data} = await axios.get(`${DOMAIN}/api/admin/departement`);
            setDepartement(data);
          } catch (error) {
            console.log(error);
          }
        };
        fetchData();
      }, []);

  return (
    <>
      <div className="formulaire-person2">
        <div className="formulaire-wrapper">
          <div className="formulaire-left">
            <h2 className="form-h2">PHOTO</h2>
            {!photo && <img src={userImg} alt="" className="form-img" />}
            <div className="form-img-rows">
              <div className="form-img-row">
                <input type="radio" name="source" value="import" checked={source === 'import'} onChange={handleSourceChange} className='radio-img' />
                <span className="form-title-img">Importer une photo</span>
              </div>
              <div className="form-img-row">
                <input type="radio" name="source" value="webcam" checked={source === 'webcam'} onChange={handleSourceChange} className='radio-img' />
                <span className="form-title-img">Prendre une photo avec la webcam</span>
              </div>
              {source === 'import' && (
                <div>
                  <input
                    type="file"
                    name="photo"
                    onChange={handleFileChange}
                    lable="Profil"
                    id='file-upload'
                    accept='.jpeg, .png, .jpg'
                    className="form-img2"
                  />
                  <CloudUploadOutlined onClick={() => document.getElementById('file-upload').click()} className='form-icon'/>
                  {photo && (
                    <div className="crop-container">
                      <Cropper
                        image={photo}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        minCropWidth={300}
                        minCropHeight={400}
                      />
                    </div>
                  )}
                </div>
              )}
              {source === 'webcam' && (
                <div>
                  <Webcam audio={false} ref={webcamRef} className="pop-img" />
                  <div className="crop-container">
                    <Cropper
                      image={webcamRef.current?.getScreenshot()}
                      crop={crop}
                      zoom={zoom}
                      aspect={1}
                      onCropChange={setCrop}
                      onZoomChange={setZoom}
                    />
                  </div>
                </div>
              )}
            </div>
            </div>
            <div className="formulaire-right">
                    <form action="" className="form-center">
                        <h2 className="form-h2"><span>*</span> Detail Personnel :</h2>
                        <div className="form-rows">
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Nom <span>*</span></label>
                                <input type="text"  name='first_name'  className="input-form" onChange={handleChange} placeholder='Entrez votre nom..' />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Prenom <span>*</span></label>
                                <input type="text" name="last_name" className="input-form" onChange={handleChange} placeholder='Entrez votre postnom..' />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Email <span>*</span></label>
                                <input type="text"  name='email' className="input-form" onChange={handleChange} placeholder='Entrez votre adresse email..' />
                            </div>
                        </div>

                        <div className="form-rows">
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Adresse <span>*</span></label>
                                <input type="text"  name='address' required className="input-form" onChange={handleChange} placeholder='Entrez votre adresse..' />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Date de naissace <span>*</span></label>
                                <input type="date" name="date_of_birth" required className="input-form" onChange={handleChange} />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Etat civil <span>*</span></label>
                                <Select
                                  name="etat_civil"
                                  options={[
                                    { value: 'celibataire', label: 'Célibataire' },
                                    { value: 'marie(e)', label: 'Marié(e)' }
                                  ]}
                                  onChange={(selectedOption) => handleSelectChange(selectedOption, "etat_civil")}
                                />
                            </div>
                        </div>
                        <div className="form-rows">

                            <div className="form-row">
                                <label htmlFor="" className="label-form">Nombre d'enfant <span>*</span></label>
                                <input type="number" name='nombre_enfant' className="input-form" onChange={handleChange} placeholder="Entrez votre nombre d'enfant.." />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Telephone <span>*</span></label>
                                <input type="tel" name='phone_number' className="input-form" onChange={handleChange} placeholder="Entrez votre numero de tel.."  />
                            </div>

                            <div className="form-row">
                                <label htmlFor="" className="label-form">Genre <span>*</span></label>
                                <div className="form-radio">
                                  <input type="radio" id="Choice1" onChange={handleChange} checked={data.gender === 'Homme'} name="gender" value="homme" />
                                  <label for="Choice1">Homme</label>
                                  <input type="radio" id="Choice2" onChange={handleChange} checked={data.gender === 'Femme'} name="gender" value="femme" />
                                  <label for="Choice2">Femme</label>
                                  <input type="radio" id="Choice3" onChange={handleChange} checked={data.gender === 'Autres'} name="gender" value="autres" />
                                  <label for="Choice3">autres</label>
                                </div>
                            </div>
                        </div>
                        <h2 className="form-h2"><span>*</span> Detail Professionel :</h2>
                        <div className="form-rows">
                            <div className="form-row">
                                <label htmlFor="" className="label-form">N° INPP <span>*</span></label>
                                <input type="text"  name='number_inpp' className="input-form" onChange={handleChange} placeholder="Entrez votre numero inpp.." />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">CNSS <span>*</span></label>
                                <input type="text" name='number_cnss' className="input-form" onChange={handleChange} placeholder="Entrez votre numero cnss.." />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Numero du pièce <span>*</span></label>
                                <input type="text"  name='identification_number' className="input-form" onChange={handleChange} placeholder='Entrez votre numero du pièce..' />
                            </div>
                        </div>

                        <div className="form-rows">
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Type du pièce <span>*</span></label>
                                <Select
                                    name="identification_type"
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, "identification_type")}
                                    options={type.map((item) => ({
                                        value: item.nom_type,
                                        label: item.nom_type
                                    }))}
                                />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Domaine <span>*</span></label>
                                <Select
                                    name="skills"
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, "skills")}
                                    options={departement.map((item) => ({
                                        value: item.id,
                                        label: item.nom_departement
                                    }))}
                                />
                            </div>
                            <div className="form-row">
                                <label htmlFor="" className="label-form">Niveau d'étude <span>*</span></label>
                                <Select
                                    name="certifications"
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, "certifications")}
                                    options={niveau.map((item) => ({
                                        value: item.titre,
                                        label: item.titre
                                    }))}
                                />
                            </div>
                            <div className="form-row">
                                <label htmlFor="pet-select" className="label-form">Status <span>*</span></label>
                                <Select
                                    name="employment_status"
                                    onChange={(selectedOption) => handleSelectChange(selectedOption, "employment_status")}
                                    options={statusE.map((item) => ({
                                        value: item.nom_status,
                                        label: item.nom_status
                                    }))}
                                />
                            </div>
                        </div>
                        <button className="form-btn" onClick={handlePhotoSubmit}>Envoyer <SendIcon className='form-icon' /></button>
                    </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default Formulaire2